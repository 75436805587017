import {CurrencyPipe, DatePipe} from '@angular/common';
import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ApprovalHistoryDto, ProposalModel} from 'src/app/core/scfi/services/services-proposal/proposal.dto';
import {ProposalService} from 'src/app/core/scfi/services/services-proposal/proposal.service';
import {AbstractScfi} from 'src/app/pages/scfi/abstract-scfi';
import {ProposalStatusRoleEnum} from "../../../core/scfi/services/services-proposal/enum/proposal-status-role.enum";
import {RoleTypeEnum} from "../../../core/scfi/services/services-proposal/enum/role-type.enum";

@Component({
    selector: 'proposal-approval-details',
    templateUrl: './proposal-approval-details.component.html',
    styleUrls: ['./proposal-approval-details.component.scss']
})
export class ProposalApprovalDetailsComponent extends AbstractScfi implements OnChanges {
    @Input() proposalFull: ProposalModel;
    public approvalHistory: ApprovalHistoryDto[];
    public proposal: ProposalModel;


    constructor(
        private proposalService: ProposalService,
        protected currencyPipe: CurrencyPipe,
        protected datePipe: DatePipe,
    ) {
        super(currencyPipe, datePipe);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.proposalFull && changes.proposalFull.currentValue) {
            this.proposal = changes.proposalFull.currentValue;
            this.approvalHistory = this.proposal.history;
        }
        }

    public translateRoleType(roleType: RoleTypeEnum) {
        switch (roleType) {
            case RoleTypeEnum.CLIENT:
                return 'Cliente';
            case RoleTypeEnum.COMMITTEE:
                return 'Comitê';
            case RoleTypeEnum.COMMERCIAL:
                return 'Comercial'
            case RoleTypeEnum.TREASURY:
                return 'Tesouraria';
            case RoleTypeEnum.GUARANTOR:
                return 'Avalista';
            case RoleTypeEnum.BACKOFFICE:
                return 'Backoffice'
            default:
                return roleType;
        }
    }


    translateProposalRoleType(role: ProposalStatusRoleEnum):string  {
        switch (role) {
            case ProposalStatusRoleEnum.COMMITTEE:
                return 'Comitê';
            case ProposalStatusRoleEnum.BACKOFFICE:
                return 'Backoffice';
            case ProposalStatusRoleEnum.TREASURY:
                return 'Tesouraria';
            case ProposalStatusRoleEnum.GUARANTOR:
                return 'Avalista';
            case ProposalStatusRoleEnum.CONTRACT_SIGNING:
                return 'Assinatura de contrato';
            case ProposalStatusRoleEnum.SIGNED_CONTRACT:
                return 'Contrato assinado';
            case ProposalStatusRoleEnum.REJECTED:
                return 'Recusada';
            case ProposalStatusRoleEnum.SIMULATION:
                return 'Simulação';

                default:
                    return role;
        }
    }
}

import { Injectable, Injector } from "@angular/core";

import { ApiService } from "src/app/core/_base/_auth/api.service";
import { SpeViewNFgtsProxy } from "src/app/core/proxy/spe/spe-view-n-fgts.proxy";
import { Observable } from 'rxjs';
import { IConfirmFgtsProposalCancelationDto } from "src/app/utils/proposal-cancelation-types";
import { SpeNFgtsProxy } from "src/app/core/proxy/spe/spe-n-fgts.proxy";

@Injectable({
  providedIn: 'root'
})

export class FgtsService {

  constructor(
    protected injector: Injector,
    protected api: ApiService,
    private speViewNFgtsProxy: SpeViewNFgtsProxy,
    private speNFgtsProxy: SpeNFgtsProxy
  ) { }

  getProposalsList(startDate: any, endDate: any): Observable<any> {
    return this.speViewNFgtsProxy.getProposalsList(startDate, endDate);
  }

  getEventsListByUuid(uuid:string){
    return this.speViewNFgtsProxy.getEventsListByUuid(uuid);
  }

  getProposalByUuid(uuidProposal: string): Observable<any> {
    return this.speViewNFgtsProxy.getProposalByUuid(uuidProposal);
  }

  getContractByUuid(uuidProposal: string): Observable<any> {
    return this.speViewNFgtsProxy.getContractByUuid(uuidProposal);
  }

  getCCB(uuidProposal: string) {
    return this.speViewNFgtsProxy.getCCB(uuidProposal);
  }

  getCancelationRequestByProposalUuid(uuidProposal: string){
    return this.speViewNFgtsProxy.getCancelationRequestByProposalUuid(uuidProposal);
  }

  postConfirmCancelationRequest(data:IConfirmFgtsProposalCancelationDto){
    return this.speNFgtsProxy.postConfirmCancelationRequest(data);
  }

}

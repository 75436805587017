import { InstallmentDetailsDTO } from './../../../pages/consigned/operations/proposals/services-apis/poposals-modal';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { InstallmentDetailsModel, ProposalDetailModel, ProposalDto, ProposalModel } from 'src/app/core/scfi/services/services-proposal/proposal.dto';

@Component({
    selector: 'proposal-installments-details',
    templateUrl: './proposal-installments-details.component.html',
    styleUrls: ['./proposal-installments-details.component.scss']
})
export class ProposalInstallmentsDetailsComponent implements OnChanges{
    @Input() proposalFull: ProposalModel;
    public proposal: ProposalModel;


    ngOnChanges() {
    }
}

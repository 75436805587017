<div id="proposal-installments-details">
    <section class="card mb-4 shadow-sm">
        <div class="card-header border-0 d-flex align-items-center justify-content-start">
            <span [inlineSVG]="'./assets/media/icons/duotune/abstract/abs029.svg'"
                class="svg-icon svg-icon-2tx svg-icon-gray-800 me-4"></span>
            <h3 class="fw-bolder card-title mt-3">Detalhes das Parcelas</h3>
        </div>

        <div class="card-body border-top p-9">
            <div class="d-flex">
                <h5 class="col-3 px-10">
                    Nº de Parcelas:
                </h5>
                <h5 class="col-3 px-10">
                    Valor da Parcela:
                </h5>
                <h5 class="col-3 px-10">
                    Valor Total da Proposta (M):
                </h5>
                <h5 class="col-3 px-10">
                    Total de Juros:
                </h5>
            </div>

            <div class="d-flex">
                <div class="col-3 px-10">
                    {{ proposalFull.details.versionProposal ? proposalFull.details.versionProposal : ""}}
                </div>
                <div class="col-3 px-10">
                    <ng-container *ngIf="proposalFull.installment && proposalFull.installment.length">
                        {{ proposalFull.installment[0].installment | currency:"BRL" }}
                    </ng-container>
                </div>
                 
                <div class="col-3 px-10">
                    {{proposalFull.details.value | currency:"BRL" }}
                </div>
                <div class="col-3 px-10">
                    <ng-container *ngIf="proposalFull.installment && proposalFull.installment.length">
                        {{ proposalFull.installment[0].installment | currency:"BRL" }}
                    </ng-container>
                </div>
            </div>

            <div class="d-flex mt-10">
                <h5 class="col-3 px-10">
                    Valor Solicitado:
                </h5>

                <h5 class="col-3 px-10">
                    Taxa de Juros:
                </h5>

                <h5 class="col-3 px-10">
                    Tributos (IOF):
                </h5>

                <h5 class="col-3 px-10">
                    TAC:
                </h5>
            </div>

            <div class="d-flex">
                <div class="col-3 px-10">
                    {{ proposalFull.details.value| currency:"BRL" }}
                </div>

                <div class="col-3 px-10">
                    {{ proposalFull.details.interestRate | percent: '1.0-2' }}
                </div>
                <div class="col-3 px-10">
                    {{ proposalFull.details.iofIndex | percent: '1.0-2'}}
                </div>

                <div class="col-3 px-10">
                    {{ proposalFull.details.valueTac| currency:"BRL" }}
                </div>
            </div>
        </div>
    </section>
</div>

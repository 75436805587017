<div class="table-finance">
  <div class="card-header">
    <h3 class="card-title">Detalhes Financeiros do Contrato</h3>
  </div>

  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <table class="table-finance-table text-center mat-elevation-z1 table table-striped table-hover mb-0">
          <thead class="p-3">
            <tr>
              <th class="text-bg-success text-center text_color_black" scope="col text-center">Solicitado </th>
              <th scope="col text-center">Parcela</th>
              <th scope="col text-center">+TAC</th>
              <th scope="col text-center">IOF Princ.</th>
              <th scope="col text-center">+IOF Princ.</th>
              <th scope="col text-center">Total de Juros</th>
            </tr>
          </thead>
          <tbody class="disabled">
            <tr>
              <td class="text-bg-success text-center text_color_black">
                {{proposal.details.value | currency:"BRL"}}
              </td>
              <td>
                <ng-container *ngIf="proposal.installment && proposal.installment.length">
                  {{proposal.installment[0].installment| currency:"BRL"}}
                </ng-container>
              </td>
              <td>
                {{proposal.details.valueTac | currency:"BRL"}}
              </td>
              <td class="text-bg-success text-center text_color_black">
                {{proposal.details.iofIndex | currency:"BRL"}}
              </td>
              <td>
                {{proposal.details.iofDailyIndex | currency:"BRL"}}
              </td>
              <td>
                <ng-container *ngIf="proposal.installment && proposal.installment.length">
                  {{proposal.installment[0].fees| currency:"BRL"}}
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
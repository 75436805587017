export enum RoleTypeEnum {
    CLIENT = 'CLIENTE',
    COMMITTEE = 'COMITE',
    BACKOFFICE = 'BACKOFFICE',
    COMMERCIAL = 'COMERCIAL',
    GUARANTOR = 'AVALISTA',
    TREASURY = 'TESOURARIA',
}






<ng-container *ngIf="goBackToInvoice">
  <app-page-header [page-title]="creationPageTitle()" button-text="Voltar" [button-link]="urlInvoice"
    button-class="btn-danger">
  </app-page-header>
</ng-container>
<ng-container *ngIf="!goBackToInvoice">
  <app-page-header [page-title]="creationPageTitle()" button-text="Voltar" button-link="/operations/contracts-loans/"
    button-class="btn-danger">
  </app-page-header>
</ng-container>

<div class="container-fluid px-0 mt-4 contract">
  <form [formGroup]="resourceForm" class="">

    <div class="card g-12 disabled">
      <div class="card-header">
        <h3 class="card-title">Dados para Simulação</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <app-ui-c-input [name]="'CPF Colaborador'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'employeeCpf'" [type]="'text'" [isCPF]="true">
            </app-ui-c-input>
          </div>
          <div class="col-md-3">
            <app-ui-c-input [name]="'Nome Colaborador'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'name'" [type]="'text'">
            </app-ui-c-input>
          </div>
          <div class="col-md-3">
            <app-ui-c-input [name]="'Empresa'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'company'" [type]="'text'">
            </app-ui-c-input>
          </div>
          <div class="col-md-3">
            <app-ui-c-input [name]="'Admissão'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'admission'" [type]="'text'">
            </app-ui-c-input>
          </div>
        </div>
        <!--Linha 2-->
        <div class="row my-1">
          <div class="col-md-3">
            <app-ui-c-input [name]="'Celular'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'celphone'" [type]="'text'" [isPhone]="true">
            </app-ui-c-input>
          </div>
          <div class="col-md-3">
            <app-ui-c-input [name]="'E-mail'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'email'" [type]="'text'">
            </app-ui-c-input>
          </div>

          <div class="col-md-3" *ngIf="!isAttendant()">
            <app-ui-c-input [name]="'Salário'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'salary'" [type]="'text'" [isMoney]="true" [isNumber]="true">
            </app-ui-c-input>
          </div>
          <div class="col-md-3">
            <app-ui-c-input [name]="'Gerente Departamento'" [form]="resourceForm"
              [errorMessages]="errorMessagesProposal" [formName]="'manResponsible'" [type]="'text'">
            </app-ui-c-input>
          </div>
        </div>
        <div class="row my-5" *ngIf="!!proposal?.proposalPartner?.numberCcb">
          <div class="col-12">
            <label class="form-label fs-6 fw-bolder text-dark">
              Número da CCB:
              {{
              !!proposal?.proposalPartner?.numberCcb ?
              proposal?.proposalPartner?.numberCcb : ""
              }}
              - {{
              !!proposal?.proposalPartner?.partner ?
              proposal?.proposalPartner?.partner.replace("_"," ") : ""
              }}
            </label>
          </div>
        </div>
      </div>
    </div>

    <!-- Iniciamos Dados do ContraCheques-->
    <div class="card g-12 my-2 disabled" *ngIf="!isAttendant()">
      <div class="card-header">
        <h3 class="card-title">Dados Contracheque/Holerite</h3>
      </div>
      <div class="card-body">

        <!-- Linha 1-->
        <div class="row my-1">
          <div class="col-md-4">
            <app-ui-c-input [name]="'Pensão Alimentícia'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'alimony'" [type]="'text'" [isNumber]="true" [isMoney]="true">
            </app-ui-c-input>
          </div>
          <div class="col-md-4">
            <app-ui-c-input [name]="'Cobrança Judicial'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'judicialcollection'" [type]="'text'" [isNumber]="true" [isMoney]="true">
            </app-ui-c-input>
          </div>
          <div class="col-md-4">
            <app-ui-c-input [name]="'Mensalidade Sindical'" [form]="resourceForm"
              [errorMessages]="errorMessagesProposal" [formName]="'unionmonthly'" [type]="'text'" [isNumber]="true"
              [isMoney]="true">
            </app-ui-c-input>
          </div>
          <div class="col-md-4">
            <app-ui-c-input [name]="'Outros Emprest. BrasPress'" [form]="resourceForm"
              [errorMessages]="errorMessagesProposal" [formName]="'otherdiscounts'" [type]="'text'" [isNumber]="true"
              [isMoney]="true">
            </app-ui-c-input>
          </div>
        </div>
        <!--Linha 2-->
        <div class="row my-1 mt-4">
          <div class="col-md-3">
            <app-ui-c-input [name]="'Outros Empréstimos'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'otherloans'" [type]="'text'" [isNumber]="true" [isMoney]="true">
            </app-ui-c-input>
          </div>
          <div class="col-md-3">
            <app-ui-c-input [name]="'Empréstimos Urbano Bank'" [form]="resourceForm"
              [errorMessages]="errorMessagesProposal" [formName]="'urbanobankloan'" [type]="'text'" [isNumber]="true"
              [isMoney]="true">
            </app-ui-c-input>
          </div>
          <div class="col-md-3">
            <app-ui-c-input [name]="'INSS'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'inss'" [type]="'text'" [isNumber]="true" [isMoney]="true">
            </app-ui-c-input>
          </div>
          <div class="col-md-3">
            <app-ui-c-input [name]="'Imposto de Renda'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'incometax'" [type]="'text'" [isNumber]="true" [isMoney]="true">
            </app-ui-c-input>
          </div>
        </div>
        <!-- Linha 2 FINAL-->
        <!-- Linha de Ações Continuar-->
        <div class="row my-1">
          <div class="col-md-3">
            <app-ui-c-input [name]="'Total de Descontos'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'tatoaldiscounts'" [type]="'text'" [isNumber]="true" [isMoney]="true">
            </app-ui-c-input>
          </div>
          <!-- SOU O LATERAL-->
          <!-- <div class="col-md-3">
            <app-ui-c-input [name]="'PRÉ Aprovado'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'preaproved'" [type]="'text'">
            </app-ui-c-input>
          </div> -->

          <!-- <div class="col-md-4 d-flex align-items-end">
            <button class="btn btn-info float-right none">Valor Máximo da Prestação</button>
          </div> -->
          <!-- FIM DO LATERAL-->
          <!-- Final Linha de Ações Continuar-->
        </div>

      </div>
    </div>

    <div class="card g-12 my-2 disabled">
      <div class="card-header">
        <h3 class="card-title">Dados Bancários</h3>
      </div>

      <div class="card-body">
        <div class="row">

          <div class="col-12  col-md-3">
            <app-ui-c-input [name]="'Número Banco'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'bankNumber'">
            </app-ui-c-input>
          </div>
          <div class="col-12  col-md-3">
            <app-ui-c-input [name]="'Nome Banco'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'bankName'">
            </app-ui-c-input>
          </div>
          <div class="col-12  col-md-3">
            <app-ui-c-input [name]="'Agência'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'agency'">
            </app-ui-c-input>
          </div>
          <div class="col-12  col-md-3">
            <app-ui-c-input [name]="'Número Conta'" [form]="resourceForm" [errorMessages]="errorMessagesProposal"
              [formName]="'bankAccount'">
            </app-ui-c-input>
          </div>
          <div class="col-12  col-md-3">
            <ng-container>
              <label class="mt-3 mb-0 form-label fs-6 fw-bolder text-dark">Tipo de Conta</label>
              <select formControlName="bankAccountType" name="bankAccountType" data-control="bankAccountType"
                data-hide-search="true" class="form-select form-select-solid form-select-lg fw-bold w-100">
                <option value="0">Conta Pagamento </option>
              </select>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <!-- Detalhes de aprovação -->
    <div class="card g-12 my-2">
      <div class="card-header">
        <h3 class="card-title">Detalhes de Aprovação</h3>
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <table
              class="detail-contract text-center table-details mat-elevation-z1 table table-striped table-hover mb-0">
              <thead class="p-3">
                <tr>
                  <th class="bold" scope="col">
                    Papel
                  </th>
                  <th scope="col text-center">
                    Quem
                  </th>
                  <th scope="col text-center">
                    Data
                  </th>
                  <th scope="col text-center">
                    Hora
                  </th>
                  <th scope="col text-center">
                    Status
                  </th>
                  <th scope="col text-center">
                    Observação
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let history of historyApprovation">
                  <td> {{ preprarePaper(history?.status) }}</td>
                  <td> {{history?.customerName}}</td>
                  <td> {{history?.dateRegister | date: "dd/MM/yyyy" }} </td>
                  <td> {{history?.dateRegister | date: "HH:mm":"UTC-3" }} </td>
                  <td> {{prepareStatus(history?.status)}} </td>
                  <td> {{prepareStatus(history?.status, history?.description)}} </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- Detalhes de aprovação -->

    <ng-container *ngIf="proposal?.price != undefined">
      <div class="card g-12 my-2">
        <detail-contract [contractName]="proposal?.proposalName" [price]="proposal?.price"></detail-contract>
      </div>
    </ng-container>

    <ng-container *ngIf="proposal?.priceDetails != undefined">
      <div class="card g-12 my-2">
        <table-finance-contract-detail [proposal]="proposal"></table-finance-contract-detail>
      </div>
    </ng-container>



    <!-- Listagem das parcelas -->
    <div class="card g-12 my-2">
      <div class="card-header">
        <h3 class="card-title">Detalhes das Parcelas</h3>
      </div>
      <div class="card-body">

        <div class="row my-1">
          <h5 class="col-3">
            Quantidade
          </h5>
          <h5 class="col-3">
            Valor da parcela
          </h5>
          <h5 class="col-3">
            (M) Valor total da proposta
          </h5>
          <h5 class="col-3">
            Juros totais
          </h5>
        </div>

        <div class="row my-1">
          <div class="col-3">
            {{installmentsDetails.installmentQuantityLoan}}x
          </div>
          <div class="col-3">
            {{installmentsDetails.pinStallmentValue | currency:"BRL" }}
          </div>
          <div class="col-3">
            {{installmentsDetails.fvTotalAmountLoanValue | currency:"BRL" }}
          </div>
          <div class="col-3">
            {{installmentsDetails.totalInterest | currency:"BRL" }}
          </div>
        </div>
        <br>
        <div class="row my-1">
          <h5 class="col-3">
            Valor Solicitado
          </h5>

          <h5 class="col-3">
            Taxa de juros
          </h5>

          <h5 class="col-3">
            Tributos(IOF)
          </h5>

          <h5 class="col-3">
            TAC
          </h5>

        </div>
        <div class="row my-1">
          <div class="col-3">
            {{installmentsDetails.amountProposal | currency:"BRL" }}
          </div>

          <div class="col-3">
            {{installmentsDetails.iProposalInterestMonth}}%
          </div>

          <div class="col-3">
            {{installmentsDetails.iof | currency:"BRL"}}
          </div>

          <div class="col-3">
            {{installmentsDetails.taxAdministration | currency:"BRL" }}
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-3 " *ngIf="proposal?.statusProposal  != 'PAID'">
            <button (click)="paidContract()" class="btn btn-success float-right">Depositar contrato</button>
          </div>

          <div class="col-12 col-md-5 " *ngIf="!(proposal?.statusProposal  != 'PAID')">
            <span class="bold p-2 rounded-pill text-bg-success text_color_white">
              Contrato depositado em: {{proposal.dateUpdated | date: "dd/MM/yyyy - HH:mm" }}
            </span>
          </div>
        </div>
      </div>
    </div>


    <!-- Parcelas -->

  </form>
  <div class="card g-12 my-2">
    <div class="card-header">
      <h3 class="card-title">Parcelas</h3>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <table mat-table matSort [dataSource]="dataSourceSimulador" multiTemplateDataRows
            class="table-installment mat-elevation-z1 table table-striped table-hover mb-0">

            <ng-container matColumnDef="dateInstallment">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Data de vencimento
              </th>
              <td mat-cell *matCellDef="let element"> {{element.dateInstallment | date: "dd/MM/yyyy"}} </td>
            </ng-container>

            <ng-container matColumnDef="installmentsNumber">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Período
              </th>
              <td mat-cell *matCellDef="let element"> {{element.numberInstallment}} </td>
            </ng-container>

            <ng-container matColumnDef="interestInstallment">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                (J) Juros*
              </th>
              <td mat-cell *matCellDef="let element"> {{element.interestInstallment | currency:"BRL"}} </td>
            </ng-container>

            <ng-container matColumnDef="amortInstallment">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Amortização
              </th>
              <td mat-cell *matCellDef="let element"> {{element.amortInstallment | currency:"BRL" }} </td>
            </ng-container>

            <ng-container matColumnDef="installmentValue">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                (P) Parcela
              </th>
              <td mat-cell *matCellDef="let element"> {{truncateService.truncate(element.installmentValue,2)}} </td>
            </ng-container>

            <ng-container matColumnDef="amountDue">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Saldo devedor
              </th>
              <td mat-cell *matCellDef="let element"> {{element.amountDue | currency:"BRL" }} </td>
            </ng-container>

            <ng-container matColumnDef="daysIofIndex">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                IOF
              </th>
              <td mat-cell *matCellDef="let element"> {{element.valueDaysIof | currency:"BRL" }} </td>
            </ng-container>

            <ng-container matColumnDef="insuranceValue">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Seguro
              </th>
              <td mat-cell *matCellDef="let element"> {{element.insuranceValue | currency:"BRL" }} </td>
            </ng-container>

            <ng-container matColumnDef="total">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Total Folha
              </th>
              <!-- proposal?.proposalPartner?.partner === "MONEY_PLUS" -->
              <td mat-cell *matCellDef="let element">
                {{sumElemets(element.installmentValue,element.valueDaysIof,element.insuranceValue) |
                currency:"BRL":'symbol':'1.2-2' }}
              </td>
            </ng-container>

            <ng-container matColumnDef="installmentStatus">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Situação da parcela
              </th>
              <td class="center" mat-cell *matCellDef="let element">
                <span [class]="'p-2 rounded-pill ' + checkStatus(element.installmentStatus)?.color">{{
                  checkStatus(element.installmentStatus)?.installmentStatus }} </span>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplayInstallment"></tr>
            <tr class="center" mat-row *matRowDef="let row; columns: columnsToDisplayInstallment;"></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

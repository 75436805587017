import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CurrencyPipe, DatePipe, Location } from '@angular/common';
import { AbstractScfi } from '../../abstract-scfi';
import { ProposalService } from 'src/app/core/scfi/services/services-proposal/proposal.service';
import { FormGroup } from '@angular/forms';
import { CompanyDto, ErrorMessagesProposalDto, PersonDto, PriceDto, ProposalDto, ProposalModel } from 'src/app/core/scfi/services/services-proposal/proposal.dto';
import { MaskService } from 'src/app/shared/services-ultil/masker-service/masker.service';
import * as moment from 'moment';

@Component({
  selector: 'app-proposal-form',
  templateUrl: './proposal-form.component.html',
  styleUrls: ['./proposal-form.component.scss']
})
export class ProposalFormComponent extends AbstractScfi implements OnInit {
  public errorMessagesProposal: ErrorMessagesProposalDto;
  public formProposal: FormGroup;
  public proposal: ProposalModel;
  public price: ProposalModel;
  public document : ProposalDto;
  public isPF: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private location: Location,
    private proposalService: ProposalService,
    protected currencyPipe: CurrencyPipe,
    protected datePipe: DatePipe,
    private maskService: MaskService,
  ) {
    super(currencyPipe, datePipe);
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const uuid = params['uuid'];
  

      if (uuid) {
        this.buildFormProposal();
        this.findProposalFullByUuid(uuid);
      } else {
        this.buildFormProposal();
      }
    });
  }

  public findProposalFullByUuid(uuid: string) {
    this.proposalService.findProposalFullByUuid(uuid).subscribe({
      next: (proposal: ProposalModel) => {
        this.proposal = proposal;
        this.cdr.detectChanges();
      },
      error: (error) => {
        this.showErrorModal('Erro ao buscar proposta!');
      }
    });
  }

  public buildFormProposal() {
    this.errorMessagesProposal = this.proposalService.getErrorMessages();
    this.formProposal = this.proposalService.createFormProposal();
  }

  public backToLastPage(): void {
    this.location.back();
  }


}

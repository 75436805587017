import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/_base/_auth/api.service';
import { Observable } from "rxjs";
import { SpeViewNScfiProxy } from "../../../proxy/view/spe-view-n-scfi.proxy";
import { AddressTypeDto } from './address-type.dto';
import { BacenIspbDto } from './bacen-ispn.dto';

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    constructor(
        protected api: ApiService,
        private speViewNScfiProxy: SpeViewNScfiProxy,
    ) { }

    public findAddressType(): Observable<AddressTypeDto[]> {
        return this.speViewNScfiProxy.findAddressType();
    }

    public getBacenIspb(): Observable<BacenIspbDto[]> {
        return this.speViewNScfiProxy.getBacenIspb();
    }
}

<div class="custom-modal modal-search-bank">

    <div class="modal-content">

        <div class="modal-title">
            <h1>Selecione o Banco</h1>
        </div>

        <div class="modal-search">
            <mat-form-field appearance="fill">
                <mat-label>
                    Pesquisar
                </mat-label>
                <input matInput placeholder="Pesquisar" (keyup)="filterBankList($event)" />
            </mat-form-field>
        </div>

        <div class="modal-list">
            <mat-list>
                <mat-list-item *ngFor="let bank of bankFilter" (click)="selectBank(bank)" class="modal-list-item"
                    [class.selected]="selectedBank === bank">
                    <span>{{bank.code}} - {{bank.name}}</span>
                </mat-list-item>
            </mat-list>
        </div>

        <div class="modal-buttons">
            <button type="button" class="btn btn-danger" (click)="handleClickClose()">Fechar</button>
            <button type="button" class="btn btn-primary" (click)="handleClickSubmit()">Selecionar</button>
        </div>
    </div>
</div>
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { AbstractScfi } from "../../abstract-scfi";
import { CurrencyPipe, DatePipe, Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { CustomerService } from "src/app/core/scfi/services/services-customer/customer.service";
import { FormGroup } from "@angular/forms";
import { CustomerContactDto, CustomerDto, ErrorMessagesCustomerDto, StateDto } from "src/app/core/scfi/services/services-customer/customer.dto";
import { NgxSpinnerService } from "ngx-spinner";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { Observable } from "rxjs";
import { TableData } from "src/app/core/scfi/services/services-table/table.dto";
import { MaskService } from "src/app/shared/services-ultil/masker-service/masker.service";
import { TypeCustomerEnum } from "src/app/core/scfi/services/services-product/type-customer.enum";
import { CepService } from "src/app/core/scfi/services/services-cep/cep.service";
import { CepDetailsDto } from "src/app/core/scfi/services/services-cep/cep-details.dto";
import { CommonService } from "src/app/core/scfi/services/services-common/common.service";
import { AddressTypeDto } from "src/app/core/scfi/services/services-common/address-type.dto";
import { MatDialog } from "@angular/material/dialog";
import { ModalSearchBankComponent } from "src/app/modals/modal-search-bank/modal-search-bank.component";

export interface PartnerTable {
  name: string,
  cpf: string,
  email: string,
  phone: number
}

@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss']
})

export class CustomerFormComponent extends AbstractScfi implements OnInit {
  public formCustomerIdentification: FormGroup;
  public formCustomerData: FormGroup;
  public formCustomerAddress: FormGroup;
  public formCustomerBankAccount: FormGroup;
  public formCustomerContact: FormGroup;
  public errorMessagesCustomer: ErrorMessagesCustomerDto;
  public isEditMode: boolean = false;
  public uuid: string;
  public isPjClient: boolean;
  public customerType: TypeCustomerEnum;
  public documentDate: Date;
  public partner: PartnerTable[];
  public partnerTable: TableData;
  public formCustomerDoc: FormGroup;
  public states: StateDto[];
  public addressTypeList: AddressTypeDto[];

  constructor(
    protected currencyPipe: CurrencyPipe,
    protected datePipe: DatePipe,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private customerService: CustomerService,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private maskService: MaskService,
    private cepService: CepService,
    private commonService: CommonService,
    private dialog: MatDialog,
  ) {
    super(currencyPipe, datePipe);
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.customerType = params['type'];
      this.uuid = params['uuid'];
      this.isEditMode = !!this.uuid;

      this.isPjClient = this.customerType === TypeCustomerEnum.PJ;

      this.buildFormCustomer();
      this.states = this.customerService.getListStates();
      if (this.uuid) this.findCustomerByUuid(this.uuid);
    });

    this.findAddressTypeList();
  }

  public backToLastPage(): void {
    this.location.back();
  }

  public changeClientType(e: MatSlideToggleChange) {
    this.isPjClient = e.checked;
    this.buildFormCustomer();
    this.formCustomerIdentification.reset();
    this.formCustomerData.reset();
    this.formCustomerAddress.reset();
    this.formCustomerBankAccount.reset();
    this.formCustomerContact.reset();
    this.formCustomerDoc.reset();
  }

  public buildFormCustomer() {
    this.formCustomerIdentification = this.customerService.createFormCustomerIdentification();
    this.formCustomerData = this.customerService.createFormCustomerData();
    this.formCustomerAddress = this.customerService.createFormCustomerAddress();
    this.formCustomerBankAccount = this.customerService.createFormCustomerBankAccount();
    this.formCustomerContact = this.customerService.createFormCustomerContact();
    this.formCustomerDoc = this.customerService.createFormCustomerDoc();
    this.errorMessagesCustomer = this.customerService.getErrorMessages();
  }

  public findCustomerByUuid(uuid: string) {
    this.spinner.show();
    this.customerService.findCustomerByUuid(uuid).subscribe({
      next: async (customer: CustomerDto) => {
        const contact = customer.contact[0];
        const address = customer.address[0];
        const bankAccount = customer.bankAccount[0];

        this.formCustomerIdentification.patchValue({
          document: customer.document,
          name: customer.name,
          fantasyName: customer.fantasyName,
        })

        this.formCustomerAddress.patchValue({
          uuidTypeAddress: address?.uuidTypeAddress,
          zipcode: address?.zipcode,
          street: address?.street,
          number: address?.number,
          complement: address?.complement,
          neighborhood: address?.neighborhood,
          city: address?.city,
          state: address?.state,
          country: address?.country,
        });

        this.formCustomerBankAccount.patchValue({
          bankNumber: bankAccount?.bankNumber,
          bankName: bankAccount?.bankName,
          agency: bankAccount?.agency,
          accountNumber: bankAccount?.accountNumber,
          accountDigit: bankAccount?.digit,
          accountType: bankAccount?.accountType
        });

        this.formCustomerContact.patchValue({
          phone: contact?.phone,
          email: contact?.email,
        })

        this.cdr.detectChanges();
        this.spinner.hide();
      },
      error: () => {
        this.showErrorModal('Erro ao buscar cliente!');
        this.spinner.hide();
      }
    });
  }

  public async saveCustomer() {
    const confirmationMessage = this.isEditMode
      ? 'Tem certeza que deseja alterar este cliente?'
      : 'Tem certeza que deseja criar este cliente?';

    const confirmed = await this.showConfirmationModal(confirmationMessage);

    if (confirmed) {
      const formCustomer = this.formCustomerIdentification.value;

      if (this.isEditMode) {
        this.updateCustomer(formCustomer);
      } else {
        this.createCustomer(formCustomer);
      }
    }
  }

  public async saveCustomerIdentification() { }

  public async saveCustomerData() { }

  public async saveCustomerContact() { }

  public async saveCustomerAddress() {
    const confirmed = await this.showConfirmationModal('Tem certeza que deseja adicionar este endereço?');

    if (confirmed) {
      const addressForm = this.formCustomerAddress.value;

      const customerAddress = {
        ...addressForm,
        uuidCustomer: this.uuid
      };

      this.customerService.createOrUpdateCustomerAddress(customerAddress).subscribe({
        next: async () => {
          await this.showSuccessModal('Endereço adicionado com sucesso!')
          this.findCustomerByUuid(this.uuid);
        },
        error: () => {
          this.showErrorModal('Erro ao adicionar endereço!');
        }
      });
    }
  }

  public async saveCustomerBankAccount() { }

  public updateCustomer(formCustomer: CustomerDto) {
    this.customerService.createCustomer(formCustomer).subscribe({
      next: async () => {
        await this.showSuccessModal('Cliente criado com sucesso!');
        this.router.navigate(['/scfi/customers/customer-list']);
      },
      error: () => {
        this.showErrorModal('Erro ao criar o cliente!');
      }
    });
  }

  public createCustomer(formCustomer: CustomerDto) {
    this.customerService.updateCustomer(formCustomer).subscribe({
      next: async () => {
        await this.showSuccessModal('Dados do cliente alterados com sucesso!');
        this.router.navigate(['/scfi/customers/customer-list']);
      },
      error: () => {
        this.showErrorModal('Erro ao alterar os dados do cliente!');
      }
    });
  }

  generatePartnerData(): PartnerTable[] {
    return [
      { name: 'ALBERTO LIMA', cpf: '00000000000', email: 'email@email.com', phone: 11998887766 },
      { name: 'NEIDE FAGUNDES', cpf: '00000000000', email: 'email@email.com', phone: 11998887766 },
      { name: 'CLEUSA ROSA', cpf: '00000000000', email: 'email@email.com', phone: 11998887766 },
      { name: 'NELSON LIMEIRA', cpf: '00000000000', email: 'email@email.com', phone: 11998887766 },
      { name: 'ELSA FIGUEIRO', cpf: '00000000000', email: 'email@email.com', phone: 11998887766 },
    ];
  }

  generatePartnerTable(): TableData {
    return {
      title: 'Sócios',
      header: ['Nome', 'Documento', 'E-mail', 'Telefone', 'Ações'],
      rows: this.partner.map(row => {
        return {
          fields: [
            row.name,
            this.maskService.formatDocumentCPF(row.cpf),
            row.email,
            this.maskService.formatPhone(row.phone.toString())
          ],
          actions: [
            { icon: './assets/media/icons/duotune/general/gen055.svg', onClick: () => this.redirectToEditPartner(false) },
            { icon: './assets/media/icons/duotune/general/gen027.svg', onClick: () => { } }
          ]
        }
      }),
    }
  }

  redirectToEditPartner(isNewPartner: boolean) {
    if (isNewPartner) {
      this.router.navigate(['/scfi/customers/new-customer']);
    }
    else {
      const uuidPartner = '01';
      this.router.navigate(['/scfi/customers/edit-customer', uuidPartner]);
    }
  }

  public getAddressByCep(cep: string) {
    cep = cep?.replace(/\D/g, '');

    if (!cep || cep.length < 8) {
      this.showErrorModal('CEP inválido ou incompleto!');
      return;
    }

    this.spinner.show();
    this.cepService.getAddressByCep(cep).subscribe({
      next: async (cepDetails: CepDetailsDto) => {

        this.formCustomerAddress.patchValue({
          zipcode: cepDetails?.cep,
          street: cepDetails?.logradouro,
          complement: cepDetails?.complemento,
          neighborhood: cepDetails?.bairro,
          city: cepDetails?.localidade,
          state: cepDetails?.uf,
        })
        this.cdr.detectChanges();
        this.spinner.hide();
      },
      error: () => {
        this.showErrorModal('Erro ao buscar CEP!');
        this.formCustomerAddress.reset();
        this.spinner.hide();
      }
    });
  }

  public findAddressTypeList() {
    this.commonService.findAddressType().subscribe({
      next: (addressType: AddressTypeDto[]) => {
        this.addressTypeList = addressType;
        this.cdr.detectChanges();
      },
      error: (error) => {
        this.showErrorModal('Erro ao buscar os tipos de endereço!');
      }
    });
  }

  public openSearchBankModal() {

    const dialogRef = this.dialog.open(ModalSearchBankComponent, {
      disableClose: true,
    });

    dialogRef.componentInstance.onSubmit.subscribe(selectedBank => {
      if (!selectedBank) {
        this.showErrorModal('Selecione um banco!');
        return;
      }

      this.formCustomerBankAccount.patchValue({
        bank: `${selectedBank.code} - ${selectedBank.name}`,
        bankNumber: selectedBank.code,
        bankName: selectedBank.name
      })

      dialogRef.close();
    })
  }
}

import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {ClipboardModule} from 'ngx-clipboard';
import {TranslateModule} from '@ngx-translate/core';
import {InlineSVGModule} from 'ng-inline-svg-2';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {environment} from 'src/environments/environment';
import {SharedModule} from './shared/shared.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {NgxSpinnerModule} from "ngx-spinner";
import {KeycloakAngularModule, KeycloakService} from "keycloak-angular";
import {DOCUMENT, registerLocaleData} from "@angular/common";
import localept from '@angular/common/locales/pt';
import { PagesModule } from './pages/pages.module';
import { ModalsModule } from './modals/modals.module';

registerLocaleData(localept, 'pt');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    ClipboardModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    SharedModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    NgxSpinnerModule,
    KeycloakAngularModule,
    PagesModule,
    ModalsModule
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'pt'},
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      deps: [KeycloakService, DOCUMENT],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function initializer(keycloak: KeycloakService): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      const {keycloakConfig} = environment;
      let redirectUrl = document.location.protocol + '//' + document.location.hostname;

      if (document.location.hostname.includes('localhost')) {
        redirectUrl += ':' + document.location.port;
      }

      try {
        await keycloak.init({
          config: keycloakConfig,
          initOptions: {
            checkLoginIframe: false,
            // redirectUri: redirectUrl + '/app',
            silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html'
          },
          bearerExcludedUrls: [
              '/assets',
              '/proposal/confirmation'
          ],
          enableBearerInterceptor: true,
        });
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  };
}

import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/core/_base/_auth/api.service';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from "rxjs";
import { CreateCustomerAddressDto, CustomerDto, StateDto } from "./customer.dto";
import { SpeViewNScfiProxy } from "../../../proxy/view/spe-view-n-scfi.proxy";
import { TypeCustomerDto } from "./type-customer.dto";
import { SpeNScfiProxy } from 'src/app/core/proxy/spe/spe-n-scfi.proxy';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {

    formCustomer: FormGroup;
    formCustomerAddress: FormGroup;
    formCustomerBankAccount: FormGroup;
    formCustomerContact: FormGroup;
    formCustomerIdentification: FormGroup;
    formCustomerData: FormGroup;
    formCustomerDoc: FormGroup;

    constructor(
        protected api: ApiService,
        private formBuilder: FormBuilder,
        private speViewNScfiProxy: SpeViewNScfiProxy,
        private speNScfiProxy: SpeNScfiProxy
    ) { }

    public getErrorMessages() {
        return {
            document: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                }
            ],
            name: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                }
            ],
            phone: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
            ],
            email: [
                {
                    type: 'required',
                    message: 'Campo obrigatório'
                },
                {
                    type: 'email',
                    message: 'Digite um e-mail válido'
                },
            ]
        }
    }

    public createFormCustomerIdentification(): FormGroup {
        this.formCustomerIdentification = this.formBuilder.group({
            document: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            name: new FormControl('', Validators.compose([
                Validators.required,
            ])),
            fantasyName: new FormControl(''),
        })
        return this.formCustomerIdentification;
    }

    public createFormCustomerData(): FormGroup {
        this.formCustomerData = this.formBuilder.group({
            motherName: new FormControl(''),
            fatherName: new FormControl(''),
            birthday: new FormControl(''),
            stateRegistration: new FormControl(''),
            rg: new FormControl(''),
            issuer: new FormControl(''),
            issueDate: new FormControl(''),
            score: new FormControl('')
        })
        return this.formCustomerData;
    }

    public createFormCustomerAddress(): FormGroup {
        this.formCustomerAddress = this.formBuilder.group({
            uuidTypeAddress: new FormControl(''),
            zipcode: new FormControl(''),
            street: new FormControl(''),
            number: new FormControl(''),
            complement: new FormControl(''),
            neighborhood: new FormControl(''),
            city: new FormControl(''),
            state: new FormControl(''),
            country: new FormControl(''),
        })
        return this.formCustomerAddress;
    }

    public createFormCustomerBankAccount(): FormGroup {
        this.formCustomerBankAccount = this.formBuilder.group({
            bank: new FormControl(''),
            bankNumber: new FormControl(''),
            bankName: new FormControl(''),
            agency: new FormControl(''),
            accountNumber: new FormControl(''),
            accountDigit: new FormControl(''),
            accountType: new FormControl(''),
        })
        return this.formCustomerBankAccount;
    }

    public createFormCustomerContact(): FormGroup {
        this.formCustomerContact = this.formBuilder.group({
            phone: new FormControl(''),
            email: new FormControl(''),
        })
        return this.formCustomerContact;
    }

    public createFormCustomerDoc(): FormGroup {
        this.formCustomerDoc = this.formBuilder.group({
            comment: new FormControl(''),
        })
        return this.formCustomerDoc;
    }

    public findTypeCustomer(): Observable<TypeCustomerDto[]> {
        return this.speViewNScfiProxy.findTypeCustomer();
    }

    public findCustomerList(): Observable<CustomerDto[]> {
        return this.speViewNScfiProxy.findCustomerList();
    }

    public findCustomerByUuid(uuid: string): Observable<CustomerDto> {
        return this.speViewNScfiProxy.findCustomerByUuid(uuid);
    }

    public findCustomerByDocument(document: string) {
        return this.speViewNScfiProxy.findCustomerByDocument(document)
    }

    public findContactCustomerByUuid(uuidCustomer: string) {
        return this.speViewNScfiProxy.findContactCustomerByUuid(uuidCustomer)
    }

    public createCustomer(customer: CustomerDto) {
        return this.speNScfiProxy.createCustomer(customer)
    }

    public updateCustomer(customer: CustomerDto) {
        return this.speNScfiProxy.updateCustomer(customer)
    }

    public getListStates(): StateDto[] {
        return [
            { 'name': 'AC', 'value': 'AC' },
            { 'name': 'AL', 'value': 'AL' },
            { 'name': 'AM', 'value': 'AM' },
            { 'name': 'AP', 'value': 'AP' },
            { 'name': 'BA', 'value': 'BA' },
            { 'name': 'CE', 'value': 'CE' },
            { 'name': 'DF', 'value': 'DF' },
            { 'name': 'ES', 'value': 'ES' },
            { 'name': 'GO', 'value': 'GO' },
            { 'name': 'MA', 'value': 'MA' },
            { 'name': 'MG', 'value': 'MG' },
            { 'name': 'MS', 'value': 'MS' },
            { 'name': 'MT', 'value': 'MT' },
            { 'name': 'PA', 'value': 'PA' },
            { 'name': 'PB', 'value': 'PB' },
            { 'name': 'PE', 'value': 'PE' },
            { 'name': 'PI', 'value': 'PI' },
            { 'name': 'PR', 'value': 'PR' },
            { 'name': 'RJ', 'value': 'RJ' },
            { 'name': 'RN', 'value': 'RN' },
            { 'name': 'RR', 'value': 'RR' },
            { 'name': 'RS', 'value': 'RS' },
            { 'name': 'SC', 'value': 'SC' },
            { 'name': 'SE', 'value': 'SE' },
            { 'name': 'SP', 'value': 'SP' },
            { 'name': 'TO', 'value': 'TO' },
            { 'name': 'RO', 'value': 'RO' }
        ];
    }

    public createOrUpdateCustomerAddress(customerAddres: CreateCustomerAddressDto) {
        return this.speNScfiProxy.createOrUpdateCustomerAddress(customerAddres)
    }
}

import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {InstallmentDetailsModel, ProposalModel} from 'src/app/core/scfi/services/services-proposal/proposal.dto';
import { ProposalService } from 'src/app/core/scfi/services/services-proposal/proposal.service';
import { TableData } from 'src/app/core/scfi/services/services-table/table.dto';
import { AbstractScfi } from 'src/app/pages/scfi/abstract-scfi';

@Component({
    selector: 'installments-table',
    templateUrl: './installments-table.component.html',
    styleUrls: ['./installments-table.component.scss']
})

export class InstallmentsTableComponent extends AbstractScfi implements OnChanges {
    @Input() proposalFull: ProposalModel;
    public proposal: ProposalModel;
    public tableData: TableData;


    constructor(
        protected currencyPipe: CurrencyPipe,
        protected datePipe: DatePipe,
        private proposalService: ProposalService,
    ) {
        super(currencyPipe, datePipe);
    }


    ngOnChanges(changes: SimpleChanges) {
        if (changes.proposalFull && changes.proposalFull.currentValue) {
            this.proposal = changes.proposalFull.currentValue;
            this.tableData = this.generateTableData();
            this.sumValuesTableInstallment();
        }
    }


    public sumValuesTableInstallment() {
        let feesInstallment: number = 0;
        let amortizationInstallment: number = 0;
        let installmentValue: number = 0;
        this.proposal.installment?.forEach((el) => {
            feesInstallment += Number(el.fees);
            amortizationInstallment += Number(el.amortization);
            installmentValue += Number(el.installment);
        });
        this.tableData.rows.push({
            fields: [
                '',
                '',
                this.formatValueToMoney(feesInstallment),
                this.formatValueToMoney(installmentValue),
                this.formatValueToMoney(amortizationInstallment),
                ''
            ],
            actions: [],
        });
    }

    public generateTableData(): TableData {
        return {
            header: ['Período', 'Data de Vencimento', 'Juros (J)', 'Parcela (P)', 'Amortização', 'Salvo devedor',  ''],
            rows:  this.proposal?.installment?.map(row => {
                const period = row.period  ? row.period : '- - -' ;
                const dateInstallment = this.formatDate(row.dateInstallment.toString());  
                const valueInstallment = this.formatValueToMoney(row.installment) ;
                const  amortization =   this.formatValueToMoney(row.amortization) ;
                const fees =  this.formatValueToMoney(row.fees );
                const  balanceDue = this.formatValueToMoney(row.balanceDue);

                return {
                    fields: [
                        period,
                        dateInstallment,
                        fees,
                        valueInstallment,
                        amortization,
                        balanceDue,
                    ],
                    actions: [],
                }
            }),
        }
    }
}

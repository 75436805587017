import { Component, Input, OnInit } from '@angular/core';
import { ProposalModel } from 'src/app/core/scfi/services/services-proposal/proposal.dto';

@Component({
  selector: 'detail-contract',
  templateUrl: './detail-contract.component.html',
  styleUrls: ['./detail-contract.component.scss']
})
export class TableContractDetailComponent implements OnInit {

  @Input() price?: any;
  @Input() contractName?: string;
  constructor() {
  }
  
  ngOnInit(): void {
        
  }

  converterNumber(value: string, fixed?: number) {
    return Number(value).toFixed(fixed ? fixed : 2)
  }

}

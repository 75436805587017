import { Component, Input, OnInit } from '@angular/core';
import {ProposalDto, ProposalModel} from 'src/app/core/scfi/services/services-proposal/proposal.dto';

@Component({
  selector: 'table-finance-contract-detail',
  templateUrl: './table-finance-contract.component.html',
  styleUrls: ['./table-finance-contract.component.scss']
})
export class TableFinanceContractDetailComponent implements OnInit {

  @Input() proposal: ProposalModel;

  constructor() { }

  ngOnInit(): void {
  }

  converterNumber(value: string) {
    return Number(value).toFixed(2)
  }

}

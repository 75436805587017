import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from "ng-inline-svg-2";
import { UiCTableModule } from 'src/app/shared/components/ui-c-table/ui-c-table.module';
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { SharedModule } from "src/app/shared/shared.module";
import { UiCInputModule } from "src/app/shared/components/ui-c-input/ui-c-input.module";
import { TokenAcceptComponent } from "src/app/shared/components/token-accept-proposal/token-accept.component";
import { MatDialogModule } from "@angular/material/dialog";
import { ModalTokenComponent } from './modal-token/modal-token.component';
import { ModalSearchBankComponent } from './modal-search-bank/modal-search-bank.component';
import { MatListModule } from "@angular/material/list";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';


@NgModule({
  imports: [
    CommonModule,
    InlineSVGModule,
    UiCTableModule,
    MatSlideToggleModule,
    InlineSVGModule,
    SharedModule,
    UiCInputModule,
    MatDialogModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule
  ],
  declarations: [
    ModalTokenComponent,
    ModalSearchBankComponent
  ],
  entryComponents: [
    ModalTokenComponent,
    ModalSearchBankComponent
  ]
})
export class ModalsModule { }

<div class="detail-contract">
  <div class="card-header">
    <h3 class="card-title">Detalhes do Contrato - {{contractName}}</h3>
  </div>

  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <table class="detail-contract-table text-center mat-elevation-z1 table table-striped table-hover mb-0">
          <thead class="p-3">
            <tr>
              <th scope="col ">
                Juros a.m
              </th>
              <th scope="col text-center">
                Juros a.d
              </th>
              <th scope="col text-center">
                TAC
              </th>
              <th scope="col text-center">
                IOF Principal
              </th>
              <th scope="col text-center">
                IOF Diário
              </th>
              <th scope="col text-center">
                Seguro
              </th>
              <th scope="col text-center">
                Parc. Min
              </th>
              <th scope="col text-center">
                Parc. Max
              </th>
              <th scope="col text-center">
                Parc. Escolhida
              </th>
              <th class="text-bg-warning text-center text_color_black" scope="col">
                Dias Car.
              </th>
              <th class="text-bg-warning text-center text_color_black" scope="col">
                Total Car.
              </th>
              <th scope="col text-center">
                Solicitado
              </th>
              <th scope="col text-center">
                Aprovado
              </th>
              <th scope="col text-center">
                Início
              </th>
              <th scope="col text-center">
                Fim
              </th>
            </tr>
          </thead>
          <tbody class="disabled ">
            <tr>
              <td>
                 {{ converterNumber( price?.priceInterestRate) }}%
              </td>
              <td>
                 {{converterNumber(price?.interestDay,4)}}%
              </td>
              <td>
                 {{price?.priceTac | currency:'BRL'}}
              </td>
              <td>
                 {{converterNumber(price?.priceIof, 4)}}%
              </td>
              <td>
                 {{converterNumber(price?.priceDaysIofIndex, 4)}}%
              </td>
              <td>
                 {{converterNumber(price?.priceInsuranceIndex, 4)}}%
              </td>
              <td>
                 {{price?.priceMinInstallment}}X
              </td>
              <td>
                 {{price?.priceMaxInstallment}}X
              </td>
              <td>
                 {{price?.installmentOption}}X
              </td>
              <td class="text-bg-warning text-center text_color_black">
                 {{price?.differentDays}} Dias
              </td>
              <td class="text-bg-warning text-center text_color_black">
                 {{price?.lackValue | currency:'BRL'}}
              </td>
              <td>
                 {{price?.requestDate | date: "dd/MM/yyyy"}}
              </td>
              <td>
                 {{ price?.aproveDate != null ? [price?.aproveDate | date: "dd/MM/yyyy"] :'Aguardando'}}
              </td>
              <td>
                 {{price?.installmentStartDay | date: "dd/MM/yyyy"}}

              </td>
              <td>
                 {{price?.installmentEndDay | date: "dd/MM/yyyy"}}
                </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

import { CurrencyPipe, DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { NgxSpinnerService } from 'ngx-spinner';
import { BacenIspbDto } from 'src/app/core/scfi/services/services-common/bacen-ispn.dto';
import { CommonService } from 'src/app/core/scfi/services/services-common/common.service';
import { AbstractScfi } from 'src/app/pages/scfi/abstract-scfi';

@Component({
    selector: 'modal-search-bank',
    templateUrl: './modal-search-bank.component.html',
    styleUrls: ['./modal-search-bank.component.scss']
})
export class ModalSearchBankComponent extends AbstractScfi implements OnInit {
    public bankList: BacenIspbDto[];
    public bankFilter: BacenIspbDto[];
    public selectedBank: BacenIspbDto;
    @Output() onSubmit = new EventEmitter<BacenIspbDto>();

    constructor(
        protected currencyPipe: CurrencyPipe,
        protected datePipe: DatePipe,
        private dialogRef: MatDialogRef<ModalSearchBankComponent>,
        private commonService: CommonService,
        private spinner: NgxSpinnerService,
        private cdr: ChangeDetectorRef
    ) {
        super(currencyPipe, datePipe)
    }

    ngOnInit(): void {
        this.getBankList();
    }

    public getBankList() {
        this.spinner.show();
        this.commonService.getBacenIspb().subscribe({
            next: async (bankList: BacenIspbDto[]) => {
                this.bankList = bankList;
                this.bankFilter = bankList;
                this.cdr.detectChanges();
                this.spinner.hide();
            },
            error: () => {
                this.showErrorModal('Erro ao buscar bancos!');
                this.spinner.hide();
            }
        });
    };

    public selectBank(bank: BacenIspbDto) {
        this.selectedBank = bank;
    }

    public filterBankList(event: Event) {
        const inputElement = event.target as HTMLInputElement;
        const keyword = inputElement.value;

        if (keyword === undefined || keyword === '') {
            this.bankFilter = this.bankList;
        } else {
            this.bankFilter = this.bankList.filter((unit) => (
                unit.code + ' - ' + unit.name.normalize("NFD").replace(/[^a-zA-Z0-9s]/g, "").toUpperCase()
            ).indexOf(keyword.normalize("NFD").replace(/[^a-zA-Z0-9s]/g, "").toUpperCase()) > -1);
        }
    }

    public handleClickClose(): void {
        this.dialogRef.close();
    }

    public handleClickSubmit(): void {
        this.onSubmit.emit(this.selectedBank);
    }
}

